import {
  CineonToneMapping,
  Group,
  PMREMGenerator,
  PerspectiveCamera,
  Scene,
  WebGLRenderer,
} from "three"
import { RGBELoader } from "three/examples/jsm/loaders/RGBELoader.js"
import { OrbitControls } from "./OrbitControls"
import gsap from "gsap"

export default class GL {
  innerHeight: number
  innerWidth: number
  scene: Scene
  element: Element
  bounds: DOMRect
  canvas: any
  renderer: any
  camera: PerspectiveCamera
  fov: number
  viewport: { height: number; width: number }
  controls: any
  composer: any
  constructor(element: string, ...group: Group[]) {
    this.element = window.$(element)
    gsap.set(this.element, { autoAlpha: 0 })
    const bounds = this.element.getBoundingClientRect()
    this.bounds = bounds
    this.innerHeight = bounds.height
    this.innerWidth = bounds.width
    this.create()
    this.scene.add(...group)
    this.controls.enableZoom = false
    this.controls.rotateSpeed = 0.2
    this.controls.enableDamping = true
    this.controls.dampingFactor = 0.0625
    this.controls.minPolarAngle = -Math.PI / 4
    this.controls.maxPolarAngle = Math.PI / 2
  }

  create() {
    this.createRenderer()
    this.createScene()
    this.createCamera()
    this.reCalculate()
    this.canvas = this.renderer?.domElement
    this.controls = new OrbitControls(this.camera, this.canvas)
    this.controls.update()
    // this.controls.addEventListener("change", (e) =>
    // console.log(this.camera.rotation, this.camera.position)
    // )
    this.update()
  }

  update() {
    this.controls.update()
    if (this.composer) this.composer.render()
    else this.renderer.render(this.scene, this.camera)
    requestAnimationFrame(this.update.bind(this))
  }

  destroy() {}

  reCalculate() {
    const bounds = this.element.getBoundingClientRect()
    this.bounds = bounds
    this.innerHeight = bounds.height
    this.innerWidth = bounds.width
    this.camera.aspect = this.innerWidth / this.innerHeight
    this.camera.updateProjectionMatrix()
    this.renderer.setPixelRatio(Math.min(devicePixelRatio, 2))
    this.renderer.setSize(this.innerWidth, this.innerHeight)
    this.fov = this.camera.fov * (Math.PI / 180)
    const height = 2 * Math.tan(this.fov / 2) * this.camera.position.z
    const width = height * this.camera.aspect
    this.viewport = { height, width }
  }

  createRenderer() {
    this.renderer = new WebGLRenderer({ antialias: true, canvas: this.element })
    this.renderer.physicallyCorrectLights = true
    this.renderer.outputColorSpace = "srgb"
    this.renderer.toneMapping = CineonToneMapping
    this.renderer.toneMappingExposure = 1.75
    this.renderer.setClearColor("#eaecee", 0.0)
    this.renderer.setSize(this.innerWidth, this.innerHeight)
    this.renderer.setPixelRatio(Math.min(devicePixelRatio, 2))
  }

  createScene() {
    const scene = new Scene()
    this.scene = scene
    const pmremGenerator = new PMREMGenerator(this.renderer)

    const gl = this

    const hdriLoader = new RGBELoader()
    hdriLoader.load("/hdr.hdr", function (texture) {
      const envMap = pmremGenerator.fromEquirectangular(texture).texture
      texture.dispose()
      scene.environment = envMap
      gsap.to(gl.element, { autoAlpha: 1 })
    })
  }

  createCamera() {
    this.camera = new PerspectiveCamera(
      35,
      this.innerWidth / this.innerHeight,
      0.1,
      100
    )
    this.scene.add(this.camera)
    this.scene.position.x += 0
    this.camera.position.set(11.069898795914217, 1.719497971290299, 0)
  }

  zoomIn() {
    this.controls.enabled = false
    const xTarget = 0
    const yTarget = -0.7
    const zTarget = -1.65
    const tweenDuration = 2000

    function panCam(
      xTarget: number,
      yTarget: number,
      zTarget: number,
      tweenDuration: number,
      gl: GL
    ) {
      var camNewPosition = {
        x: gl.camera.position.x,
        z: gl.camera.position.z,
        y: -2,
        duration: tweenDuration,
      }
      const targetNewPos = { x: xTarget, y: yTarget, z: zTarget }

      // const camTween = gsap
      //   .timeline({
      //     onComplete: () => {
      //     },
      //   })
      //   .to(gl.camera.position, camNewPosition)
      gl.camera.position.copy(camNewPosition)

      // const targetTween = gsap
      //   .timeline({
      //     onComplete: () => {
      //       gl.controls.target.copy(targetNewPos)
      //     },
      //   })
      //   .to(gl.controls.target, camNewPosition)
    }
    panCam(0, 5, 10, 2, this)
  }
}
