import {
  AmbientLight,
  CineonToneMapping,
  Group,
  PMREMGenerator,
  PerspectiveCamera,
  PointLight,
  Scene,
  WebGLRenderer,
} from "three"
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls"
import { RGBELoader } from "three/examples/jsm/loaders/RGBELoader.js"

export default class GL2 {
  innerHeight: number
  innerWidth: number
  scene: Scene
  element: Element
  bounds: DOMRect
  canvas: any
  renderer: any
  camera: PerspectiveCamera
  fov: number
  viewport: { height: number; width: number }
  controls: any
  composer: any
  constructor(element: string, ...group: Group[]) {
    this.element = window.$(element)
    const bounds = this.element.getBoundingClientRect()
    this.bounds = bounds
    this.innerHeight = bounds.height
    this.innerWidth = bounds.width
    this.create()
    this.scene.add(...group)
    this.controls.enableZoom = false
    this.controls.enablePan = false
    this.controls.minPolarAngle = Math.PI / 2
    this.controls.maxPolarAngle = Math.PI / 2
    this.controls.enableDamping = true
    this.controls.dampingFactor = 0.075
  }

  create() {
    this.createRenderer()
    this.createScene()
    this.createCamera()
    this.reCalculate()
    this.canvas = this.renderer?.domElement
    this.controls = new OrbitControls(this.camera, this.canvas)
    this.controls.update()
    // this.controls.addEventListener("change", (e) =>
    // console.log(this.camera.rotation, this.camera.position)
    // )
    this.update()
  }

  update() {
    this.controls.update()
    if (this.composer) this.composer.render()
    else this.renderer.render(this.scene, this.camera)
    requestAnimationFrame(this.update.bind(this))
  }

  destroy() {}

  reCalculate() {
    const bounds = this.element.getBoundingClientRect()
    this.bounds = bounds
    this.innerHeight = bounds.height
    this.innerWidth = bounds.width
    this.camera.aspect = this.innerWidth / this.innerHeight
    this.camera.updateProjectionMatrix()
    this.renderer.setPixelRatio(Math.min(devicePixelRatio, 2))
    this.renderer.setSize(this.innerWidth, this.innerHeight)
    this.fov = this.camera.fov * (Math.PI / 180)
    const height = 2 * Math.tan(this.fov / 2) * this.camera.position.z
    const width = height * this.camera.aspect
    this.viewport = { height, width }
  }

  createRenderer() {
    this.renderer = new WebGLRenderer({ antialias: true, canvas: this.element })
    this.renderer.physicallyCorrectLights = true
    this.renderer.outputColorSpace = "srgb"
    this.renderer.toneMapping = CineonToneMapping
    this.renderer.toneMappingExposure = 1.75
    this.renderer.setClearColor("#F5F5F5", 1)
    this.renderer.setSize(this.innerWidth, this.innerHeight)
    this.renderer.setPixelRatio(Math.min(devicePixelRatio, 2))
  }

  createScene() {
    const scene = new Scene()
    this.scene = scene
    const pmremGenerator = new PMREMGenerator(this.renderer)

    const hdriLoader = new RGBELoader()
    hdriLoader.load("/hdr.hdr", function (texture) {
      const envMap = pmremGenerator.fromEquirectangular(texture).texture
      texture.dispose()
      scene.environment = envMap
    })

    // const light = new AmbientLight("#000000", 0.4)
    // const light2 = new PointLight("#000000", 15)
    // scene.add(light)
    // scene.add(light2)
  }

  createCamera() {
    this.camera = new PerspectiveCamera(
      35,
      this.innerWidth / this.innerHeight,
      0.1,
      100
    )
    this.scene.add(this.camera)
    // this.camera.rotation.set(
    //   -1.6150192779075072,
    //   0.9613979212686469,
    //   1.6247101346981743
    // )
    this.camera.position.set(2.5, 1, 0)
  }
}
