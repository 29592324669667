import {
  BoxGeometry,
  ConeGeometry,
  Group,
  Mesh,
  MeshBasicMaterial,
  PlaneGeometry,
} from "three"

const MarkerRed = new Mesh(
  new ConeGeometry(0.05, 0.075, 3, 1),
  new MeshBasicMaterial({ color: "#FF0000", transparent: true, opacity: 1 })
)

const MarkerGreen = new Mesh(
  new ConeGeometry(0.05, 0.075, 3, 1),
  new MeshBasicMaterial({ color: "#00FF00", transparent: true, opacity: 1 })
)

const plane = new BoxGeometry(0.5, 1.4, 0.5, 2, 2)
const MarkerBody = () => {
  const mesh = new Mesh(
    plane,
    new MeshBasicMaterial({ color: "#ACFFA5", transparent: true, opacity: 0 })
  )
  mesh.scale.set(1.2, 1.2, 1.2)
  mesh.position.y += 1
  return mesh
}

const Marker = (id: string) => {
  const gr = new Group()
  gr.name = ""
  if (window.api.books.find((x) => x.seat === id)) {
    return gr.add(MarkerRed.clone(), MarkerBody())
  } else return gr.add(MarkerGreen.clone(), MarkerBody())
}

export default class Model {
  mesh: any
  constructor(
    mesh: Mesh | Group,
    seat: string,
    attr?: {
      x?: number
      y?: number
      z?: number
      rotateX?: number
      rotateY?: number
      rotateZ?: number
      name?: string
    }
  ) {
    this.mesh = mesh.clone()
    if (attr?.x) this.mesh.position.x = attr.x
    if (attr?.y) this.mesh.position.y = attr.y
    if (attr?.z) this.mesh.position.z = attr.z
    if (attr?.rotateX) this.mesh.rotation.x = attr.rotateX
    if (attr?.rotateY) this.mesh.rotation.y = attr.rotateY
    if (attr?.rotateZ) this.mesh.rotation.z = attr.rotateZ
    this.mesh.attr = attr
    this.mesh.named = seat
    this.mesh.makers = []

    this.createMarker(attr?.name ?? "", seat)
  }

  createMarker(name: string, id: string) {
    const mesh = this.mesh

    if (name === "ergo") {
      const marker = Marker(id).clone()
      marker.name = id
      marker.position.set(mesh.position.x, 1.1, mesh.position.z)
      marker.rotation.x = Math.PI
      marker.position.z += 0.35

      this.mesh = new Group().add(mesh, marker)
      window.markers.push(marker)
    } else if (name === "ergo2") {
      const marker = Marker(id).clone()
      marker.name = id
      marker.position.set(mesh.position.x, 1.1, mesh.position.z)
      marker.rotation.x = Math.PI
      marker.position.z -= 0.35

      this.mesh = new Group().add(mesh, marker)
      window.markers.push(marker)
    } else if (name === "couch1") {
      const marker = Marker(id + "2").clone()
      marker.name = id + "2"
      marker.position.set(mesh.position.x - 2, 1.1, mesh.position.z)
      marker.rotation.x = Math.PI
      marker.position.z -= 0.15

      const marker2 = Marker(id + "1").clone()
      marker2.name = id + "1"
      marker2.position.set(mesh.position.x - 1.35, 1.1, mesh.position.z)
      marker2.rotation.x = Math.PI
      marker2.position.z -= 0.15

      const marker3 = Marker(id + "3").clone()
      marker3.name = id + "3"
      marker3.position.set(mesh.position.x - 2.65, 1.1, mesh.position.z)
      marker3.rotation.x = Math.PI
      marker3.position.z -= 0.15

      this.mesh = new Group().add(mesh, marker, marker2, marker3)
      window.markers.push(marker, marker2, marker3)

      this.mesh.position.y -= 0.05
    } else if (name === "couch11") {
      const marker = Marker(id + "5").clone()
      marker.name = id + "5"
      marker.position.set(mesh.position.x - 2, 1.1, mesh.position.z)
      marker.rotation.x = Math.PI
      marker.position.z -= 0.15

      const marker2 = Marker(id + "4").clone()
      marker2.name = id + "4"
      marker2.position.set(mesh.position.x - 1.35, 1.1, mesh.position.z)
      marker2.rotation.x = Math.PI
      marker2.position.z -= 0.15

      const marker3 = Marker(id + "6").clone()
      marker3.name = id + "6"
      marker3.position.set(mesh.position.x - 2.65, 1.1, mesh.position.z)
      marker3.rotation.x = Math.PI
      marker3.position.z -= 0.15

      this.mesh = new Group().add(mesh, marker, marker2, marker3)
      window.markers.push(marker, marker2, marker3)

      this.mesh.position.y -= 0.05
    } else if (name === "couch111") {
      const marker = Marker(id + "7").clone()
      marker.scale.x = 3
      marker.name = id + "7"
      marker.position.set(
        mesh.position.x - (2.45 + 1.6) / 2,
        1.1,
        mesh.position.z
      )
      marker.rotation.x = Math.PI
      marker.position.z -= 0.15

      // const marker2 = Marker(id + "7").clone()
      // marker2.name = id + "7"
      // marker2.position.set(mesh.position.x - 1.6, 1.1, mesh.position.z)
      // marker2.rotation.x = Math.PI
      // marker2.position.z -= 0.15

      // this.mesh = new Group().add(mesh, marker, marker2)
      this.mesh = new Group().add(mesh, marker)
      // window.markers.push(marker, marker2)
      window.markers.push(marker)

      this.mesh.position.y -= 0.05
    } else if (name === "couch2") {
      const marker = Marker(id + "5").clone()
      marker.name = id + "5"
      marker.position.set(mesh.position.x, 1.1, mesh.position.z - 2 + 4.15)
      marker.rotation.x = Math.PI
      marker.rotation.y = Math.PI / 2
      marker.position.z -= 0.15

      const marker2 = Marker(id + "4").clone()
      marker2.name = id + "4"
      marker2.position.set(mesh.position.x, 1.1, mesh.position.z - 1.45 + 4.15)
      marker2.rotation.x = Math.PI
      marker2.rotation.y = Math.PI / 2
      marker2.position.z -= 0.15

      const marker3 = Marker(id + "6").clone()
      marker3.name = id + "6"
      marker3.position.set(mesh.position.x, 1.1, mesh.position.z - 2.6 + 4.15)
      marker3.rotation.x = Math.PI
      marker3.rotation.y = Math.PI / 2
      marker3.position.z -= 0.15

      this.mesh = new Group().add(mesh, marker, marker2, marker3)
      window.markers.push(marker, marker2, marker3)

      this.mesh.position.y -= 0.05
    } else if (name === "couch22") {
      const marker = Marker(id + "11").clone()
      marker.name = id + "11"
      marker.position.set(mesh.position.x, 1.1, mesh.position.z - 2 + 4.15)
      marker.rotation.x = Math.PI
      marker.rotation.y = Math.PI / 2
      marker.position.z -= 0.15

      const marker2 = Marker(id + "10").clone()
      marker2.name = id + "10"
      marker2.position.set(mesh.position.x, 1.1, mesh.position.z - 1.45 + 4.15)
      marker2.rotation.x = Math.PI
      marker2.rotation.y = Math.PI / 2
      marker2.position.z -= 0.15

      const marker3 = Marker(id + "12").clone()
      marker3.name = id + "12"
      marker3.position.set(mesh.position.x, 1.1, mesh.position.z - 2.6 + 4.15)
      marker3.rotation.x = Math.PI
      marker3.rotation.y = Math.PI / 2
      marker3.position.z -= 0.15

      this.mesh = new Group().add(mesh, marker, marker2, marker3)
      window.markers.push(marker, marker2, marker3)

      this.mesh.position.y -= 0.05
    } else if (name === "couch222") {
      const marker = Marker(id + "17").clone()
      marker.name = id + "17"
      marker.position.set(mesh.position.x, 1.1, mesh.position.z - 2 + 4.15)
      marker.rotation.x = Math.PI
      marker.rotation.y = Math.PI / 2
      marker.position.z -= 0.15

      const marker2 = Marker(id + "16").clone()
      marker2.name = id + "16"
      marker2.position.set(mesh.position.x, 1.1, mesh.position.z - 1.45 + 4.15)
      marker2.rotation.x = Math.PI
      marker2.rotation.y = Math.PI / 2
      marker2.position.z -= 0.15

      const marker3 = Marker(id + "18").clone()
      marker3.name = id + "18"
      marker3.position.set(mesh.position.x, 1.1, mesh.position.z - 2.6 + 4.15)
      marker3.rotation.x = Math.PI
      marker3.rotation.y = Math.PI / 2
      marker3.position.z -= 0.15

      this.mesh = new Group().add(mesh, marker, marker2, marker3)
      window.markers.push(marker, marker2, marker3)

      this.mesh.position.y -= 0.05
    } else if (name === "couch3") {
      const marker = Marker(id + "2").clone()
      marker.name = id + "2"
      marker.position.set(mesh.position.x, 1.1, mesh.position.z - 1.85 - 0)
      marker.rotation.x = Math.PI
      marker.rotation.y = Math.PI / 2
      marker.position.z -= 0.15

      const marker2 = Marker(id + "3").clone()
      marker2.name = id + "3"
      marker2.position.set(mesh.position.x, 1.1, mesh.position.z - 1.2 - 0)
      marker2.rotation.x = Math.PI
      marker2.rotation.y = Math.PI / 2
      marker2.position.z -= 0.15

      const marker3 = Marker(id + "1").clone()
      marker3.name = id + "1"
      marker3.position.set(mesh.position.x, 1.1, mesh.position.z - 2.5 - 0)
      marker3.rotation.x = Math.PI
      marker3.rotation.y = Math.PI / 2
      marker3.position.z -= 0.15

      this.mesh = new Group().add(mesh, marker, marker2, marker3)
      window.markers.push(marker, marker2, marker3)

      this.mesh.position.y -= 0.05
    } else if (name === "couch33") {
      const marker = Marker(id + "8").clone()
      marker.name = id + "8"
      marker.position.set(mesh.position.x, 1.1, mesh.position.z - 1.85 - 0)
      marker.rotation.x = Math.PI
      marker.rotation.y = Math.PI / 2
      marker.position.z -= 0.15

      const marker2 = Marker(id + "9").clone()
      marker2.name = id + "9"
      marker2.position.set(mesh.position.x, 1.1, mesh.position.z - 1.2 - 0)
      marker2.rotation.x = Math.PI
      marker2.rotation.y = Math.PI / 2
      marker2.position.z -= 0.15

      const marker3 = Marker(id + "7").clone()
      marker3.name = id + "7"
      marker3.position.set(mesh.position.x, 1.1, mesh.position.z - 2.5 - 0)
      marker3.rotation.x = Math.PI
      marker3.rotation.y = Math.PI / 2
      marker3.position.z -= 0.15

      this.mesh = new Group().add(mesh, marker, marker2, marker3)
      window.markers.push(marker, marker2, marker3)

      this.mesh.position.y -= 0.05
    } else if (name === "couch333") {
      const marker = Marker(id + "14").clone()
      marker.name = id + "14"
      marker.position.set(mesh.position.x, 1.1, mesh.position.z - 1.85 - 0)
      marker.rotation.x = Math.PI
      marker.rotation.y = Math.PI / 2
      marker.position.z -= 0.15

      const marker2 = Marker(id + "15").clone()
      marker2.name = id + "15"
      marker2.position.set(mesh.position.x, 1.1, mesh.position.z - 1.2 - 0)
      marker2.rotation.x = Math.PI
      marker2.rotation.y = Math.PI / 2
      marker2.position.z -= 0.15

      const marker3 = Marker(id + "13").clone()
      marker3.name = id + "13"
      marker3.position.set(mesh.position.x, 1.1, mesh.position.z - 2.5 - 0)
      marker3.rotation.x = Math.PI
      marker3.rotation.y = Math.PI / 2
      marker3.position.z -= 0.15

      this.mesh = new Group().add(mesh, marker, marker2, marker3)
      window.markers.push(marker, marker2, marker3)

      this.mesh.position.y -= 0.05
    } else if (name === "table") {
      this.mesh.position.y -= 0.015
    }
  }
}
