import { DOMImplementation, XMLSerializer } from "xmldom"
import { $App } from ".."
import JsBarcode from "jsbarcode"
import domtoimage, { domToImage } from "dom-to-image-more"
import { saveAs } from "file-saver"
import gsap from "gsap"

export default class {
  app: $App
  constructor(app: $App) {
    this.app = app
  }

  create() {
    const form = window.$(".pre__form") as unknown as HTMLFormElement

    if (form) {
      const input = window.$("input") as unknown as HTMLInputElement

      form.addEventListener("submit", async (e) => {
        e.preventDefault()
        console.log(input.value)
        location.assign(`./ticket/${input.value}`)
      })
    } else {
      JsBarcode(".barcode").init()

      function filter(node) {
        return node.tagName !== "a"
      }

      const print = window.$(".hero__print")
      // const share = window.$(".hero__submit.share")
      // share.onclick = async (e) => {
      //   e.preventDefault()
      //   const text = "My Ticket"
      //   const link = window.location.href
      //   window.open(`https://wa.me/?text=${text} ${link}`, "_blank").focus()
      // }

      print.onclick = async (e) => {
        e.preventDefault()
        await gsap.set(".ticket__wrapper", {
          scale: innerWidth < 768 ? 0.535 : 1.36,
          rotate: innerWidth < 768 ? "90deg" : "0",
        })
        domtoimage
          .toJpeg(document.querySelector(".ticket"), {
            quality: 1,
            height: innerWidth < 768 ? ((360 * innerWidth) / 390) * 1.36 : ((353 * innerWidth) / 1440) * 1.36,
            filter: filter,
            style: {
              background: "transparent",
            },
          })
          .then(function (dataUrl) {
            var link = document.createElement("a")
            link.download = "thegarageticket.jpeg"
            link.href = dataUrl
            link.click()
            gsap.set(".ticket__wrapper", {
              scale: innerWidth < 768 ? 0.55 : 1,
              rotate: innerWidth < 768 ? "-79.87deg" : "-8.43979deg",
            })
          })

        // domtoimage
        //   .toJpeg(document.querySelector(".ticket"), {
        //     quality: 1,
        //     height:
        //       innerWidth < 768
        //         ? ((360 * innerWidth) / 390) * 1.36
        //         : ((353 * innerWidth) / 1440) * 1.36,
        //     filter: filter,
        //     style: {
        //       background: "transparent",
        //     },
        //   })
        //   .then(function (dataUrl) {
        //     var link = document.createElement("a")
        //     link.download = "my-image-name.jpeg"
        //     link.href = dataUrl
        //     link.click()
        //     gsap.set(".ticket__wrapper", {
        //       scale: 0.55,
        //       rotate: innerWidth < 768 ? "-79.87deg" : "-8.43979deg",
        //     })
        //   })
      }
    }
  }

  resize() {}

  destroy() {}

  navigate() {}
}
