import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader.js"
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader"
import GL from "../../classes/GL"
import {
  Group,
  Mesh,
  MeshBasicMaterial,
  PlaneGeometry,
  Raycaster,
  Vector2,
  Vector3,
} from "three"
import Model from "../../classes/Model"
import gsap from "gsap"
import Scene2 from "./scene2"

export default class Scene {
  gl: GL
  scene2: Scene2
  constructor() {
    this.create()
  }

  async create() {
    this.scene2 = new Scene2()
    this.scene2.createErgo()
    const geometry = this.createGeometry()
  }

  createRaycaster(gl, meshes) {
    const form = window.$(".hero__left")
    const avail = window.$(".hero__availability")
    const raycaster = new Raycaster()
    const pointer = new Vector2()

    function onPointerMove(event) {
      pointer.x = (event.clientX / window.innerWidth) * 2 - 1
      pointer.y = -(event.clientY / window.innerHeight) * 2 + 1
    }

    const mex = window.markers
    const seat = window.$(".hero__seat")

    function render() {
      raycaster.setFromCamera(pointer, gl.camera)
      const intersects = raycaster.intersectObjects(mex)

      for (let i = 0; i < intersects.length; i++) {
        let object: any
        object = intersects[0]

        gl.active = object.object.parent.name
        if (!seat.innerText.includes(gl.active) && !gl.open)
          seat.innerText = gl.active
      }

      if (!intersects[0]) {
        gl.active = ""
        seat.innerText = gl.active
      }

      window.requestAnimationFrame(render)
    }

    window.addEventListener("pointermove", onPointerMove)
    // window.addEventListener("touchmove", onPointerMove)
    // window.addEventListener("touchstart", onPointerMove)
    let clickable = false
    window.target = gl.controls
    const seatInput = window.$("#seat") as unknown as HTMLInputElement

    const onclick = () => {
      if (!gl.active || gl.active === "") return
      gl.pre = gl.active
      gl.open = true
      form.classList.add("active")

      const found = window.api.books.find((x) => x.seat === gl.active)
      if (found) {
        // Set the date we're counting down to
        var countDownDate = new Date(found.expiresAt).getTime()

        // Update the count down every 1 second
        var x = setInterval(function () {
          // Get today's date and time
          var now = new Date().getTime()

          // Find the distance between now and the count down date
          var distance = countDownDate - now

          // Time calculations for days, hours, minutes and seconds
          var days = Math.floor(distance / (1000 * 60 * 60 * 24))
          var hours = Math.floor(
            (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
          )
          var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
          var seconds = Math.floor((distance % (1000 * 60)) / 1000)

          // Display the result in the element with id="demo"
          // document.querySelector(".hero__nosubmit").innerHTML =
          //   days + "d " + hours + "h " + minutes + "m " + seconds + "s "

          document.querySelector(".hero__nosubmit").innerHTML =
            "UNAVAILABLE TILL " +
            new Date(found.expiresAt).toString().split("G")[0]

          clearInterval(x)
          // If the count down is finished, write some text
          if (distance < 0) {
            clearInterval(x)
            document.querySelector(".hero__nosubmit").innerHTML =
              "THIS SPOT IS UNAVAILABLE"
          }
        }, 1000)

        form.classList.add("inactive")
        avail.className = "hero__availability unavailable"
      } else {
        avail.className = "hero__availability available"
      }
      // let x = { y: 1 }
      // gsap.to(x, { y: 0.97, onUpdate: () => this.gl.controls.dollyIn(x.y) })

      seatInput.value = gl.active ?? "A1"
      gsap.set(seat, { autoAlpha: 0 })

      if (innerWidth < 761) {
        return
      }

      gsap.to(".hero__canvas", {
        autoAlpha: 0,
        ease: "ease.out",
        duration: 0.2,
      })
      gsap.to(".hero__canvas2", {
        autoAlpha: 1,
        ease: "ease.out",
        duration: 0.2,
        delay: 0.2,
      })
      if (
        gl.active.includes("A") ||
        gl.active.includes("C5") ||
        gl.active.includes("C6") ||
        gl.active.includes("C7") ||
        gl.active.includes("C8") ||
        gl.active.includes("C9") ||
        gl.active.includes("C10")
      ) {
        this.scene2.gl.scene.add(this.scene2.couch)
        gsap.from(this.scene2.couch.position, {
          z: this.scene2.couch.position.z - 0.1,
          delay: 0.2,
          duration: 0.2,
        })
      } else if (gl.active.includes("C11") || gl.active.includes("C12")) {
        this.scene2.gl.scene.add(this.scene2.couch2)
        gsap.from(this.scene2.couch2.position, {
          z: this.scene2.couch2.position.z - 0.1,
          delay: 0.2,
          duration: 0.2,
        })
      } else {
        this.scene2.gl.scene.add(this.scene2.ergo)
        gsap.from(this.scene2.ergo.position, {
          z: this.scene2.ergo.position.z - 0.1,
          delay: 0.2,
          duration: 0.5,
          ease: "ease.out",
        })
        gsap.from(this.scene2.ergo.rotation, {
          y: this.scene2.ergo.rotation.y + Math.PI / 8,
          delay: 0.2,
          duration: 0.5,
          ease: "ease.out",
        })
      }
    }

    gl.element.ondblclick = () => onclick.call(this, gl)
    let lastClick = 0
    gl.element.addEventListener("touchstart", function (e) {
      e.preventDefault() // to disable browser default zoom on double tap
      let date = new Date()
      let time = date.getTime()
      const time_between_taps = 400 // 200ms
      pointer.x = (e.touches[0].clientX / window.innerWidth) * 2 - 1
      pointer.y = -(e.touches[0].clientY / window.innerHeight) * 2 + 1
      if (time - lastClick < time_between_taps) {
        onclick.call(this, gl)
      }
      lastClick = time
    })

    const onback = () => {
      gl.open = false
      form.classList.remove("active")
      form.classList.remove("inactive")
      avail.className = "hero__availability"
      // let x = { y: 0.97 }
      // gsap.to(x, { y: 1, onUpdate: () => this.gl.controls.dollyIn(x.y) })
      gsap.set(seat, { autoAlpha: 1 })

      gsap.to(".hero__canvas", {
        autoAlpha: 1,
        ease: "ease.in",
        duration: 0.2,
        delay: 0.2,
      })
      gsap.to(".hero__canvas2", {
        autoAlpha: 0,
        ease: "ease.in",
        duration: 0.2,
      })
      if (
        gl.pre.includes("A") ||
        gl.pre.includes("C5") ||
        gl.pre.includes("C6") ||
        gl.pre.includes("C7") ||
        gl.pre.includes("C8") ||
        gl.pre.includes("C9") ||
        gl.pre.includes("C10")
      ) {
        this.scene2.gl.scene.remove(this.scene2.couch)
      } else if (gl.pre.includes("C11") || gl.pre.includes("C12")) {
        this.scene2.gl.scene.remove(this.scene2.couch2)
      } else {
        this.scene2.gl.scene.remove(this.scene2.ergo)
      }
    }

    const back = window.$(".hero__return")
    back.onclick = () => onback.call(this, gl)

    window.requestAnimationFrame(render)
  }

  createGeometry() {
    return new Promise((resolve, reject) => {
      const gltfLoader = new GLTFLoader()
      const dracoLoader = new DRACOLoader()
      dracoLoader.setDecoderPath("")
      gltfLoader.setDRACOLoader(dracoLoader)
      let counter = 0
      let progress = window.$(".hero__canvas__loader em") as HTMLElement
      let loader = window.$(".hero__canvas__loader aside svg") as HTMLElement
      const couch = gltfLoader.loadAsync(
        "models/couch-transformed.glb",
        (e: any) => {
          if (e.loaded === e.total) {
            {
              counter++
              progress.innerText = (counter / 5) * 100 + "%"
            }
          }
        }
      )
      const couch2 = gltfLoader.loadAsync(
        "models/couch-2-transformed.glb",
        (e: any) => {
          if (e.loaded === e.total) {
            {
              counter++
              progress.innerText = (counter / 5) * 100 + "%"
            }
          }
        }
      )
      const table = gltfLoader.loadAsync(
        "models/table-transformed.glb",
        (e: any) => {
          if (e.loaded === e.total) {
            {
              counter++
              progress.innerText = (counter / 5) * 100 + "%"
            }
          }
        }
      )
      const ergo = gltfLoader.loadAsync(
        "models/ergo-transformed.glb",
        (e: any) => {
          if (e.loaded === e.total) {
            {
              counter++
              progress.innerText = (counter / 5) * 100 + "%"
            }
          }
        }
      )
      const floor = gltfLoader.loadAsync(
        "models/floor-transformed.glb",
        (e: any) => {
          if (e.loaded === e.total) {
            {
              counter++
              progress.innerText = (counter / 5) * 100 + "%"
            }
          }
        }
      )

      Promise.all([couch, couch2, table, ergo, floor]).then((models) => {
        const couch = models[0].scene
        const couch2 = models[1].scene
        const table = models[2].scene
        const ergo = models[3].scene
        const floor2 = models[4].scene

        const floor = new Model(
          new Mesh(
            new PlaneGeometry(12, 12),
            new MeshBasicMaterial({ color: "#777777", side: 2 })
          ),
          "",
          { rotateX: Math.PI / -2 }
        ).mesh

        floor.position.y -= 0.01

        floor2.scale.set(0.74, 0.74, 0.74)

        const wall1 = new Model(
          new Mesh(
            new PlaneGeometry(12, 12),
            new MeshBasicMaterial({ color: "#777777", side: 2 })
          ),
          "",
          { rotateY: Math.PI / 2, x: -6, y: -6 }
        ).mesh

        const wall2 = new Model(
          new Mesh(
            new PlaneGeometry(12, 12),
            new MeshBasicMaterial({ color: "#777777", side: 2 })
          ),
          "",
          { rotateY: Math.PI / 2, x: 6, y: -6 }
        ).mesh

        const wall3 = new Model(
          new Mesh(
            new PlaneGeometry(12, 12),
            new MeshBasicMaterial({ color: "#777777", side: 2 })
          ),
          "",
          { z: 6, y: -6 }
        ).mesh

        const wall4 = new Model(
          new Mesh(
            new PlaneGeometry(12, 12),
            new MeshBasicMaterial({ color: "#777777", side: 2 })
          ),
          "",
          { z: -6, y: -6 }
        ).mesh

        const ergos = [
          ...[0, 1, 2, 3].map((num) => {
            return new Model(ergo, `B${4 - num}`, {
              x: (8 / 6) * num - 2,
              z: -2 / 3,
              name: "ergo2",
            }).mesh
          }),
          ...[0, 1, 2, 3].map((num) => {
            return new Model(ergo, `B${num + 5}`, {
              x: (8 / 6) * num - 2,
              z: 2 / 3,
              rotateY: Math.PI,
              name: "ergo",
            }).mesh
          }),
          new Model(ergo, `C12`, {
            x: 2.4,
            z: -2.6,
            rotateY: Math.PI,
            name: "ergo",
          }).mesh,
          new Model(ergo, `C11`, {
            x: 1.6,
            z: -2.6,
            rotateY: Math.PI,
            name: "ergo",
          }).mesh,
          new Model(ergo, `C10`, {
            x: -0,
            z: -2.6,
            rotateY: Math.PI,
            name: "ergo",
          }).mesh,
          new Model(ergo, `C9`, {
            x: -0.8,
            z: -2.6,
            rotateY: Math.PI,
            name: "ergo",
          }).mesh,
          new Model(ergo, `C8`, {
            x: -4.4,
            z: -2.6,
            rotateY: Math.PI,
            name: "ergo",
          }).mesh,
        ]

        const tables = [
          new Model(table, "", { z: -1.35, x: 0.4, name: "table" }).mesh,
          new Model(table, "", { z: -1.35, x: 2.1, name: "table" }).mesh,
          new Model(table, "", { z: -1.35, x: 3.8, name: "table" }).mesh,
          new Model(table, "", {
            z: 5.35,
            x: 4.5,
            rotateY: Math.PI / -2,
            name: "table",
          }).mesh,
          new Model(table, "", {
            z: 5.35,
            x: 2,
            rotateY: Math.PI / -2,
            name: "table",
          }).mesh,
          new Model(table, "", {
            z: 5.35,
            x: -0.5,
            rotateY: Math.PI / -2,
            name: "table",
          }).mesh,
          new Model(table, "", { z: -4.5, x: 1.6, name: "table" }).mesh,
          new Model(table, "", { z: -4.5, x: 4, name: "table" }).mesh,
          new Model(table, "", { z: -4.5, x: -2.5, name: "table" }).mesh,
        ]

        const couches: Mesh[] = [
          new Model(couch, `A`, {
            z: 5.35,
            x: 4,
            rotateY: Math.PI / -2,
            name: "couch3",
          }).mesh,
          new Model(couch, `A`, {
            z: 1.35,
            x: 2.25,
            rotateY: Math.PI / 2,
            name: "couch2",
          }).mesh,
          new Model(couch, `A`, {
            z: 5.35,
            x: 1.5,
            rotateY: Math.PI / -2,
            name: "couch33",
          }).mesh,
          new Model(couch, `A`, {
            z: 1.35,
            x: -0.25,
            rotateY: Math.PI / 2,
            name: "couch22",
          }).mesh,
          new Model(couch, `A`, {
            z: 5.35,
            x: -1,
            rotateY: Math.PI / -2,
            name: "couch333",
          }).mesh,
          new Model(couch, `A`, {
            z: 1.35,
            x: -2.75,
            rotateY: Math.PI / 2,
            name: "couch222",
          }).mesh,
          new Model(couch, `C`, { z: -4, x: 4, name: "couch1" }).mesh,
          new Model(couch, `C`, { z: -4, x: 1.6, name: "couch11" }).mesh,
          new Model(couch2, `C`, { z: -4, x: -2.5, name: "couch111" }).mesh,
        ]

        this.gl = new GL(
          ".hero__canvas",
          new Group().add(
            ...ergos,
            ...tables,
            ...couches,
            floor,
            floor2,
            wall1,
            wall2,
            wall3,
            wall4
          )
        )
        this.createRaycaster(this.gl, [...couches, ...ergos])
        progress.innerText = "DONE"
        loader.classList.add("hide")

        window.$(".hero__canvas__loader button").onclick = () =>
          gsap.to(".hero__canvas__loader", { autoAlpha: 0 })
        window.$(".hero__canvas__loader aside").onclick = () =>
          gsap.to(".hero__canvas__loader", { autoAlpha: 0 })
      })
    })
  }
}
