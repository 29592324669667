import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader.js"
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader"
import GL from "../../classes/GL2"
import {
  Group,
  Mesh,
  MeshBasicMaterial,
  PlaneGeometry,
  Raycaster,
  Vector2,
  Vector3,
} from "three"
import Model from "../../classes/Model"
import gsap from "gsap"

export default class Scene2 {
  gl: GL
  ergo: any
  couch: any
  couch2: any
  constructor() {
    this.create()
  }

  async create() {}

  createErgo() {
    return new Promise((resolve, reject) => {
      const gltfLoader = new GLTFLoader()
      const dracoLoader = new DRACOLoader()
      dracoLoader.setDecoderPath("")
      gltfLoader.setDRACOLoader(dracoLoader)
      const ergo = gltfLoader.loadAsync("models/ergo-transformed.glb")
      const couch = gltfLoader.loadAsync("models/couch-transformed.glb")
      const couch2 = gltfLoader.loadAsync("models/couch-2-transformed.glb")

      Promise.all([ergo, couch, couch2]).then((models) => {
        this.ergo = new Model(models[0].scene, "").mesh
        this.couch = new Model(models[1].scene, "").mesh
        this.couch2 = new Model(models[2].scene, "").mesh
        this.ergo.rotation.y = Math.PI / 2
        this.ergo.position.y = -0.5
        this.couch.rotation.y = Math.PI / 2
        this.couch.position.y = -0.5
        this.couch.position.z = -2
        this.couch.position.x = -1
        this.couch2.rotation.y = Math.PI / 2
        this.couch2.position.y = -0.5
        this.couch2.position.z = -2
        this.couch2.position.x = -1
        // ergo.position.z += 1

        this.gl = new GL(".hero__canvas2", new Group().add())
      })
    })
  }
}
